<template>
  <div class="promo insurance">
    <div class="promo-title">Group Insurance Scheme</div>
    <div class="promo-summary">{{ page.summary}}</div>
    <div class="promo-links">
      <router-link to="/insurance" v-if="$route.name == 'index'">More Information</router-link>
      <router-link class="apply" to="/insurance/apply">Apply Now</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["page"]
}
</script>
