<template>
  <section class="content inner">
    <insurance-header :page="page"></insurance-header>
    <div class="text">
      <span v-html="page.main_text"></span>

      <downloads />
    </div>
  </section>
</template>

<script>
import InsuranceHeader from "@/components/InsuranceHeader.vue"
import api from "@/services/api"

export default {
  metaInfo() {
    return {
      title: this.$store.state.page.title || "South Yorkshire Police Federation",
      meta: [
        { name: "description", content: this.$store.state.page.meta_desc },
        { name: "keywords", content: this.$store.state.page.meta_keywords }
      ]
    }
  },
  components: {
    InsuranceHeader
  },
  created() {
    api.getPageByURL(this.$route.path)
  },
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>
